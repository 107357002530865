/* top level style rules for whole site */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h1 {
  font-size: 32px;
  font-weight: 300;
  margin-bottom: 21px;
  margin-top: 30px;
}

.h2 {
  font-size: 28px;
  font-weight: 300;
  margin: 24px 0;
}

.center {
  text-align: center;
  margin: auto;
}

.left {
  float: left;
  text-align: right;
  width: 50%;
  font-size: 20px;
  box-sizing: border-box;
  padding-right: 30px;
}

.right {
  float: right;
  text-align: left;
  width: 50%;
  font-size: 20px;
  box-sizing: border-box;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
  margin-bottom: 10px;
}


/* index.html elements */
.header {
  border-bottom: 1px solid #b2b2b2;
  padding: 20px;
}

.about {
  padding-left: 10%;
}

.footer {
  height: 80px;
  border-top: 1px solid #b2b2b2;
}

.spacer {
  height: 40px;
}

.App-Header {
  display: block;
  text-align: center;
}

/* iframe vids */
.video-wrapper {
  width: 70%;
}

@media (max-width: 767px) {
  .video-wrapper {
    width: 100%;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* About section */
.about-this-project .image-text {
  margin-left: -15px;
}

.image-text a {
  display: inline-block;
  text-decoration: none;
}

.image-text img {
  display: inline-block;
  margin-right: 15px;
  vertical-align: middle;
}

