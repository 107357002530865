.content {
  padding: 10px 10%;
}
@media (max-width: 375px) {
  .content {
    padding: 10px 0;
  }
}
@media (min-width: 375px) and (max-width: 425px) {
  .content {
    padding: 10px 5%;
  }
}

.App .recharts-wrapper {
  margin: auto;
}

.App-SummaryTable {
  border: 2px solid #b2b2b2;
  border-radius: 5px;
  font-size: 22px;
  text-align: center;
}

.App-SummaryTable div {
  border-bottom: 1px solid #b2b2b2;
  padding: 15px;
}

.App-SummaryTable div span {
  display: inline-block;
  width: 33.33%;
  vertical-align: middle;
}

